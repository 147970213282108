import axios from "axios"
import {alertConstants, tradeConstants, TRADES_URL} from "../../constants"
import AlertPresentationnal from "./AlertPresentationnal"



export const positionDetectedAlert = async (data) =>
{
    AlertPresentationnal.alert(alertConstants.POSITION_DETECTED)
    return {
        type: alertConstants.POSITION_DETECTED,
        payload: data
    }
}
export const iaDetectedAlert = async (data) =>
{
    AlertPresentationnal.alert(alertConstants.IA_DETECTED)
    return {
        type: alertConstants.POSITION_DETECTED,
        payload: data
    }
}


// Quick fix en attendant bien structurer le code
export const openAlert = async (data) =>
{
    AlertPresentationnal.alert(alertConstants.OPEN_ALERT)
    return {
        type: alertConstants.OPEN_ALERT,
        payload: data
    }
}

export const closeAlert = async (data) =>
{
    AlertPresentationnal.alert(alertConstants.CLOSE_ALERT)
    return {
        type: alertConstants.CLOSE_ALERT,
        payload: data
    }
}
