import {jwtConstants, loginConstants, websocketConstants} from '../../constants';
import * as _ from "lodash"


//LoginReducer
function loginReducer (state = {}, action)
{
    switch(action.type)
    {
        case loginConstants.LOGIN_REQUEST:
            return {
                ...state,
                isDemo: action.payload.isDemo,
                login_success: _.isNil(action.payload.login_success) === true ? null : action.payload.login_success ,
                idUser: _.isNil(action.payload.idUser) === true  ? null : action.payload.idUser ,
            };

        case loginConstants.LOGOUT:
            return {
                ...state,
                login_success: false
            };
        case websocketConstants.SOCKET_REQUEST:
            return {
                ...state,
                socketState : 1
            }
        case websocketConstants.SOCKET_ADD:
            return {
                ...state,
                socket: action.payload,
                socketState : 1
            }
        case websocketConstants.SOCKET_SUCCESS:
            return {
                ...state,
                socketState : 2
            };
        case websocketConstants.SOCKET_FAILURE:
            return {
                ...state,
                socketState : 0
            }
        case websocketConstants.SOCKET_DISCONNECTED:
            return {
                ...state,
                socketState : 0
            }




        case jwtConstants.AJOUT_JWT: return {
            ...state,
            jwtToken : action.payload,
        };


        default:
            return state
    }
}

export default loginReducer;
