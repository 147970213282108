import React, { Component } from "react";
import TradeContainer from '../../components/Trade/tradeContainer';
import SnackbarContainer from "../../components/Snackbar/SnackbarContainer"
import AlertContainer from "../../components/Alerts/AlertContainer"

class Trade extends Component {
    render() {
        return (
        <div>
            <SnackbarContainer />
            <TradeContainer />
        </div>
        );
    }
}

export default Trade;
