import { marketConstants } from '../../constants';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import brown from '@material-ui/core/colors/brown';
import teal from '@material-ui/core/colors/teal';
import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';
import {green, lightBlue} from "@material-ui/core/colors";


export const MarketsInfo =
    [
        {
            code: 'ETHUSDT_EOSUSDT',
            color: indigo[500],
            colorDisabled: indigo[100],
            sigle: 'E/E',
            long: 'ETHUSDT_EOSUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },
        {
            code: 'BTCUSDT_LTCUSDT',
            color: indigo[400],
            colorDisabled: indigo[200],
            sigle: 'B/L',
            long: 'BTCUSDT_LTCUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },
        {
            code: 'BTCUSDT_BNBUSDT',
            color: indigo[400],
            colorDisabled: indigo[200],
            sigle: 'B/B',
            long: 'ETHUSDT_EOSUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },

        {
            code: 'BTCUSDT_ETHUSDT',
            color: indigo[400],
            colorDisabled: indigo[200],
            sigle: 'B/E',
            long: 'BTCUSDT_ETHUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },

        {
            code: 'XRPUSDT_LTCUSDT',
            color: indigo[400],
            colorDisabled: indigo[200],
            sigle: 'X/L',
            long: 'XRPUSDT_LTCUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },
        {
            code: 'ADAUSDT_XLMUSDT',
            color: indigo[400],
            colorDisabled: indigo[200],
            sigle: 'A/X',
            long: 'ADAUSDT_XLMUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },

        {
            code: 'NEOUSDT_XRPUSDT',
            color: indigo[400],
            colorDisabled: indigo[200],
            sigle: 'N/X',
            long: 'NEOUSDT_XRPUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },
        {
            code: 'ZECUSDT_XMRUSDT',
            color: indigo[400],
            colorDisabled: indigo[200],
            sigle: 'Z/M',
            long: 'ZECUSDT_XMRUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },

        {
            code: 'BTCUSDT_BCHUSDT',
            color: indigo[400],
            colorDisabled: indigo[200],
            sigle: 'B/B',
            long: 'BTCUSDT_BCHUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },




    ];

export const getAllMarkets = () =>
{
    // TODO : get them from server
    return {
        type: marketConstants.GET_MARKETS,
        payload: MarketsInfo
    }
}
