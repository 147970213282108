import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Paper from '@material-ui/core/Paper';
import { getAllStrategies } from './strategyAction';
import StrategyPresentationnal from './strategyPresentationnal';
import { used_language, strategyMessageAlwaysOne, strategyMessageAbonnement, titleRestrictedAction, titleNotAllowedAction } from '../../langues';
import AlertDialog from '../AlertDialog/alertDialog';

const style = {
    paper: {
        marginTop: 30
    }
};

class StrategyContainer extends Component {
    state = {
        tab_number: 0,
      };
    
    // Enable handling the alert dialog
    close = () => this.setState({open: false});
    open = (title, msg) => this.setState({open: true, title: title, message: msg});
    
    componentDidMount() {
        this.props.getAllStrategies();
    }

    handleAddRemoveStrategy(strategy) {
        // TODO : Write request here to enable or disable a strategy from the server.
        if (strategy.disabled) {
            this.open(titleRestrictedAction[used_language], strategyMessageAbonnement[used_language]);
        }
        if (!strategy.disabled) {
            this.open(titleNotAllowedAction[used_language], strategyMessageAlwaysOne[used_language]);
        }
    }

    render() {
        return (
            <div>
                <AlertDialog title={this.state.title} text={this.state.message} open={this.state.open} handleClose={this.close.bind(this)} />
                <Paper className={this.props.classes.paper} >
                    <StrategyPresentationnal strategies={this.props.strategies} handleAddRemoveStrategy={this.handleAddRemoveStrategy.bind(this)} />
                </Paper>
            </div>
        );
    }
}

function mapStateToProps({ strategies }) {
    return { strategies };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllStrategies }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(StrategyContainer));
