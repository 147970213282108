import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getAllMarkets } from './marketAction';
import { used_language, marketTabs, marketMessageAlwaysOne, marketMessageAbonnement, titleNotAllowedAction, titleRestrictedAction } from '../../langues';
import { marketTypes } from '../../constants';
import MarketPresentationnal from './marketPresentationnal';
import AlertDialog from '../AlertDialog/alertDialog';

const style = {
    paper: {
        marginTop: 30
    }
};

class MarketContainer extends Component {
    state = {
        tab_number: 0
      };
    
    // Enable handling the alert dialog
    close = () => this.setState({open: false});
    open = (title, msg) => this.setState({open: true, title: title, message: msg});

    handleChange = (event, tab_number) => {
        this.setState({ tab_number });
    };
      
    componentDidMount() {
        this.props.getAllMarkets();
    }

    handleAddRemoveFromWallet(market) {
        // TODO : Write request here to enable or disable a market from the server.

        if (market.disabled) {
            this.open(titleRestrictedAction[used_language], marketMessageAbonnement[used_language]);
        }
        if (!market.disabled) {
            this.open(titleNotAllowedAction[used_language], marketMessageAlwaysOne[used_language]);
        }
    }
    
    render() {
        const { tab_number } = this.state;
        const filters = [x => !x.disabled, x => x.type === marketTypes.INDEX, x => x.type === marketTypes.FOREX, x => x.type === marketTypes.MATIERE_PREMIERE]
        return (
            <div>
                <AlertDialog title={this.state.title} text={this.state.message} open={this.state.open} handleClose={this.close.bind(this)} />
                <Paper className={this.props.classes.paper} >
                    <Tabs
                    value={this.state.tab_number}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    >
                        {marketTabs[used_language].map((x, i) => <Tab label={x} key={i} value={i} />)}
                    </Tabs>
                    {filters.map((f, i) => tab_number === i && <MarketPresentationnal key={i} markets={this.props.markets.filter(f)} handleAddRemoveFromWallet={this.handleAddRemoveFromWallet.bind(this)} />)}
                </Paper>
            </div>
        );
    }
}

function mapStateToProps({ markets }) {
    return { markets };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllMarkets }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(MarketContainer));