import blue from '@material-ui/core/colors/blue'
import {SERVER_IP_LOCAL, IP_SERVER_PAIRTRADING_ROOT, SERVER_WS_IP_LOCAL, IP_SERVER_PAIRTRADING} from "./config"


export const WS_S = process.env['REACT_APP_HTTPS'] === 'true' ? 'wss://' : 'ws://'
export const HTTP_S = process.env['REACT_APP_HTTPS'] === 'true'  ? 'https://' : 'http://'

console.log(HTTP_S)
export const SERVER_IP = process.env['NODE_ENV'] === 'development' ? SERVER_IP_LOCAL :  IP_SERVER_PAIRTRADING
export const WEBSOCKET_URL = process.env['NODE_ENV'] === 'development' ? SERVER_WS_IP_LOCAL : WS_S + IP_SERVER_PAIRTRADING_ROOT


export const login_route = '/'
export const routes_menu = ['/Trade', '/Market', '/Strategy', '/Stats', '/Account', 'SeDeconnecter']
export const colors_menu = [blue[200]]



export const MarketsAllowed = [

    'BTCUSDT_LTCUSDT',
    'BTCUSDT_BNBUSDT',
    'BTCUSDT_ETHUSDT',
    'BTCUSDT_BCHUSDT',

    'ETHUSDT_EOSUSDT',

    'XRPUSDT_LTCUSDT',

    'ADAUSDT_XLMUSDT',

    'NEOUSDT_XRPUSDT',

    'ZECUSDT_XMRUSDT',


]

// export const MarketsAllowed = ['FDXM_FESX']

export const configTradingView =
    {
        // "autosize": true,
        "symbol": "FOREXCOM:GRXEUR",
        "interval": "1",
        "timezone": "Europe/Paris",
        "theme": "Light",
        "style": "1",
        "locale": "fr",
        "toolbar_bg": "#f1f3f6",
        "enable_publishing": false,
        "hide_top_toolbar": true,
        "save_image": false,
    }

export const loginConstants =
    {
        LOGIN_REQUEST: 'LOGIN_REQUEST',
        LOGOUT: 'LOGOUT'
    }

export const marketConstants = {
    GET_MARKETS: 'get_markets'
}

export const marketTypes = {
    INDEX: 'Index',
    MATIERE_PREMIERE: 'Matiere Premiere',
    FOREX: 'Forex'
}

export const strategyConstants = {
    GET_STRATEGIES: 'GET_STRATEGIES'
}

export const accountConstants = {
    GET_ACCOUNTS: 'GET_ACCOUNTS'
}

export const IndicatorActionConstants = {

    CANCEL_POSITION_DETECTED : 'CANCEL_POSITION_DETECTED',
    ACCEPT_POSITION_DETECTED : 'ACCEPT_POSITION_DETECTED',

    ERROR : 'ERROR'


}
export const notificationConstants = {
    ERROR : 'ERROR',
    NEW_POSITION_DETECTED: 'NEW_POSITION_DETECTED',
    IA_DETECTED: 'IA_DETECTED',


    NEW_TRADE: 'NEW_TRADE',
    CLOSED_TRADE: 'CLOSED_TRADE',
    MAKE_ALL_SEEN: 'MAKE_ALL_NOTIF_SEEN'
}

export const alertConstants =
    {
        POSITION_DETECTED: 'POSITION_DETECTED',
        IA_DETECTED: 'IA_DETECTED',
        OPEN_ALERT: 'OPEN_ALERT',
        CLOSE_ALERT: 'CLOSE_ALERT',

    }
export const MAX_NOTIF_HUNG = 10

export const tradeConstants =
    {
        GET_TRADES: 'GET_TRADES',
        NOTIFY_NO_TRADE: 'NOTIFY_NO_TRADE',
        GET_CURRENT_TRADES: 'GET_CURRENT_TRADES',
        DISABLE_ONE_TRADE: 'DISABLE_ONE_TRADE',
        DISABLE_TAKE_PROFIT_OF_TRADE: 'DISABLE_TAKE_PROFIT_OF_TRADE'
    }

export const trade_ticks_and_prices = {

    'ETHUSDT_EOSUSDT': {tick: 1, value: 1, currency: 'USD'},

    'BTCUSDT_LTCUSDT': {tick: 1, value: 1, currency: 'USD'},
    'BTCUSDT_BNBUSDT': {tick: 1, value: 1, currency: 'USD'},
    'BTCUSDT_ETHUSDT': {tick: 1, value: 1, currency: 'USD'},
    'BTCUSDT_BCHUSDT': {tick: 1, value: 1, currency: 'USD'},

    'XRPUSDT_LTCUSDT': {tick: 1, value: 1, currency: 'USD'},
    'ADAUSDT_XLMUSDT': {tick: 1, value: 1, currency: 'USD'},

    'NEOUSDT_XRPUSDT': {tick: 1, value: 1, currency: 'USD'},
    'ZECUSDT_XMRUSDT': {tick: 1, value: 1, currency: 'USD'},

}

export const ControlPanelConstants = {
    GET_IA_STATUS: 'GET_IA_STATUS',

    GET_LEVERAGE_STATUS: 'GET_LEVERAGE_STATUS',
    GET_IA_MODE: 'GET_IA_MODE',


    SWITCH_LEVERAGE: 'SWITCH_LEVERAGE',
    SWITCH_IA: 'SWITCH_IA',
    CHANGE_IA_MODE: 'CHANGE_IA_MODE',


}


export const websocketConstants =
    {
        HANDSHAKE_RECEIVED: 'HANDSHAKE_RECEIVED',
        ERROR : 'ERROR',

        IA_DETECTED: 'IA_DETECTED',

        TRADE_OPENED: 'TradeOpened',
        TRADE_CLOSED: 'TradeClosed',

        NEW_POSITION_DETECTED: 'NEW_POSITION_DETECTED',

        POSITION_DETECTED_CANCELED: 'POSITION_DETECTED_CANCELED',
        POSITION_DETECTED_ACCEPTED: 'POSITION_DETECTED_ACCEPTED',

        PRICE_UPDATE: 'PriceUpdated',

        PAIR_PROFIT_UPDATED: 'PAIR_PROFIT_UPDATED',



        POSITION_CONSUMED: 'POSITION_CONSUMED',

        ACCURATE_UPDATE: 'AccurateUpdate',
        STRENGTH_UPDATE: 'StrengthUpdate',
        BALANCE_UPDATE: 'BalanceUpdate',
        LIQUIDATE: 'Liquidate',


        SOCKET_REQUEST : 'SOCKET_REQUEST',
        SOCKET_SUCCESS : 'SOCKET_SUCCESS',
        SOCKET_FAILURE : 'SOCKET_FAILURE',
        SOCKET_ADD: 'SOCKET_ADD',
        SOCKET_DISCONNECTED: 'SOCKET_DISCONNECTED'
    }


export const jwtConstants =
    {
        AJOUT_JWT: 'AJOUT_JWT'
    }

export const statConstants = {
    GET_STATS_PERSON_TODAY: 'GET_STATS_PERSON_TODAY',
    GET_STATS_PERSON_WEEK: 'GET_STATS_PERSON_WEEK',
    GET_STATS_PERSON_MONTH: 'GET_STATS_PERSON_MONTH',
    GET_STATS_IA_TODAY: 'GET_STATS_IA_TODAY',
    GET_STATS_IA_WEEK: 'GET_STATS_IA_WEEK',
    GET_STATS_IA_MONTH: 'GET_STATS_IA_MONTH'
}


export const dev_trade =
    {
        MARKET: 'code',
        SIDE: 'side',
        // ACCURACY: 'accurate',
        // STRENGTH: 'strength',
        // BALANCE: 'balance',
        OPEN_DATE: 'date1',
        QUANTITY_1: 'amount1',
        VALUE_1: 'value1',
        CLOSE_DATE: 'date2',
        QUANTITY_2: 'amount2',
        VALUE_2: 'value2',
        PROFIT: 'profit',
        UPDATE_TAKEPROFIT: 'update_takeprofit',
        LIQUIDATE: 'liquidate',
        STATUS: 'statut',

    }

export const dev_trade_fields = Object.keys(dev_trade).map((x) => dev_trade[x])

export const dev_trade_liquidate =
    {
        ACTION: 'action',
        MARKETS: 'markets',
        PAIR_KEY: 'pairKey',
        USER_ID: 'idUser'
    }

export const dev_trade_liquidate_fields = Object.keys(dev_trade_liquidate).map(x => dev_trade_liquidate[x])

export const dev_trade_side_sell = 'SELL'

export const dev_trade_side_buy = 'BUY'

export const dev_stats_fields = ['today', 'week', 'month']

export const dev_sort = {
    DESC: 'desc',
    ASC: 'asc'
}

export const company_name = 'ROBANK HOOD'

export const tradingview_route = '/tradingview'


const PREFIX_PARAM = '/api'

// Changer les HTTPS avec HTTP_S pour tester le SSL + WS_S avec WS || Transformer ces changements en query plutot qu'en
export const IA_STATUS_URL = HTTP_S + SERVER_IP +  PREFIX_PARAM + '/IA'
export const IA_START_URL = HTTP_S + SERVER_IP +  PREFIX_PARAM + '/IA/start'
export const IA_SHUTDOWN_URL = HTTP_S + SERVER_IP + PREFIX_PARAM + '/IA/stop'

export const IA_MODE_STATUS_URL = HTTP_S + SERVER_IP + PREFIX_PARAM + '/IA/Mode'
export const IA_MODE_AUTO_URL = HTTP_S + SERVER_IP +  PREFIX_PARAM + '/IA/Mode/Auto'
export const IA_MODE_MANUAL_URL = HTTP_S + SERVER_IP +  PREFIX_PARAM + '/IA/Mode/Manual'


export const LEVERAGE_STATUS_URL = HTTP_S + SERVER_IP +  PREFIX_PARAM + '/GetLeverage'
export const SWITCH_LEVERAGE_URL = HTTP_S + SERVER_IP +  PREFIX_PARAM + '/SwitchLeverage'


export const PAIRTRADES_URL = HTTP_S + SERVER_IP + PREFIX_PARAM + '/PairTrades'
export const LOGIN_URL = HTTP_S + SERVER_IP + PREFIX_PARAM + '/user/authorize'



export const REACT_APP_URL = HTTP_S + 'app.robankhood.com'



export const TRADING_VIEW_URL = REACT_APP_URL+ tradingview_route
export const STATS_URL_PERSON = PAIRTRADES_URL


export const ACCEPT_POTENTIAL_POSITION_URL = HTTP_S + SERVER_IP + '/api/AcceptPotentialPosition'
export const CANCEL_POTENTIAL_POSITION_URL = HTTP_S + SERVER_IP + '/api/CancelPotentialPosition'

