import React, { Component } from "react";
import MarketContainer from '../../components/Market/marketContainer';

   
class Market extends Component {
  render() {
    return (
      <div>
        <MarketContainer />
      </div>
    );
  }
}

export default Market;
