import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { dev_sort } from '../../constants';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === dev_sort.DESC ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    headers: PropTypes.array.isRequired
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { classes, order, orderBy, headers } = this.props;

    return (
      <TableHead>
        <TableRow>
          {headers.map(head => {
            return (
              <TableCell
                className={classes.cell}
                key={head.id}
                sortDirection={orderBy === head.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement='bottom-end'
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === head.id}
                    direction={order}
                    onClick={this.createSortHandler(head.id)}
                    hideSortIcon={true}
                  >
                    <div >{head.label}</div>
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 800
  },
  cell: {
    padding: '0 0 0 0',
    'text-align': 'center'
  },
  tableWrapper: {
    overflowX: 'auto'
  }
});

class EnhancedTable extends React.Component {
  state = {
    order: dev_sort.ASC,
    orderBy: '',
    page: 0,
    rowsPerPage: 25,
    sorted: false
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    orderBy: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired
  };

  static defaultProps = {
    order: dev_sort.DESC,
    orderBy: '',
    undef_default: '-',
    label_to_print: (obj,head, undef_default) => obj[head.id] !== undefined ? obj[head.id] : undef_default
  };


  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = dev_sort.DESC;

    if (this.state.orderBy === property && this.state.order === dev_sort.DESC) {
      order = dev_sort.ASC;
    }
    const sorted = true;

    this.setState({ order, orderBy, sorted });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };


  render() {
    const { classes, headers, rows, undef_default, label_to_print } = this.props;
    let { order, orderBy } = this.props;
    const { rowsPerPage, page, sorted } = this.state;

    if (sorted) {
      orderBy = this.state.orderBy;
      order = this.state.order;
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>

          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              headers={headers}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={n.timestamp1 !== undefined ? n.timestamp1 : n.id}
                    >
                    {
                      headers.map((h, key) =>
                        (<TableCell className={classes.cell} key={key}>{label_to_print(n, h, undef_default)}</TableCell>))

                    }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell className={classes.cell} colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>

        </div>
        <TablePagination
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(EnhancedTable);
