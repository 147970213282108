import {connect} from "react-redux"
import {withStyles} from "@material-ui/core"
import * as React from "react"
import AlertPresentationnal from "./AlertPresentationnal"
import {closeAlert} from "./AlertAction"
import {bindActionCreators} from "redux"




function mapStateToProps({ alerts})
{
    return { };
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({ closeAlert }, dispatch);
}


class AlertContainer extends React.Component
{
    constructor(props)
    {
        super(props)

    }

    render()
    {
        return <AlertPresentationnal />

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer)
