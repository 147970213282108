import { tradeConstants } from '../../constants';
import { websocketConstants } from '../../constants';
import _ from 'lodash';

const trade_from_current_and_receive = (current_trade, received_trade) => {
    return {...current_trade, date2: received_trade.date2, amount2: received_trade.amount2, value2: received_trade.value2}
}


// cf tradeContainer comments for more precision about the structure of data
function tradeReducer (state = {fixed: [], current: [], closing: [], tradeOpened: false, tradeClosed: false}, action)
{
    switch(action.type)
    {
        case tradeConstants.GET_TRADES:
            const all_trades = action.payload.data || []

            return {
                ...state,
                fixed: all_trades.filter(x => !_.isNil(x.date2)),
                current: all_trades.filter(x => _.isNil(x.date2)),
            };

        case tradeConstants.NOTIFY_NO_TRADE:
            return {
                ...state,
                isEmpty: action.payload
            };

        case websocketConstants.TRADE_CLOSED:
            // TODO Revoir cette partie du code par rapport  au closing
            const received_trade = action.payload;

            const current_ind = _.findIndex(state.current, x => x.code === received_trade.code);
            const current_trade = state.current[current_ind];

            const closing_ind = _.findIndex(state.closing, x => x.code === received_trade.code);

            const current_amount_init = current_trade !== undefined ? current_trade.amount1 : null;
            const current_amount_sold = current_trade !== undefined ? current_trade.amount2 : null;

            const closed_amount = received_trade.amount2;
            state.tradeOpened = false ;
            state.tradeClosed = true;

            // Premier essai de cloture du trade
            if (closing_ind === -1) {
                // Si on a tout vendu d'un coup
                if (current_amount_init === closed_amount)
                {
                    // On enleve le trade de current
                    const new_current = state.current.filter((key, i) => i !== current_ind);

                    // On l'ajoute à fixed
                    const a = {
                        ...state,
                        fixed: [trade_from_current_and_receive(current_trade, received_trade), ...state.fixed],
                        current: new_current
                    };
                    return a;
                }

                // On a fermé une partie
                else
                {
                    // On enlève le montant fermé de current
                    const new_current = state.current.map((s, key) => key === current_ind ? {...s, amount2: closed_amount} : s);

                    // On rajoute ce montant dans un état intermédiare "closing"
                    let s = trade_from_current_and_receive(current_trade, received_trade);
                    s.amount2 = current_amount_init-closed_amount;
                    const a = {
                        ...state,
                        current: new_current,
                        closing: [s, ...state.closing]
                    };
                    return a;
                }
            }
            // On a déjà commencé à le cloturer
            else {
                const closing_amount = state.closing[closing_ind].amount2;
                // Si on a vendu tout ce qu'il restait
                if (current_amount_init - current_amount_sold === closed_amount) {
                    // On enleve le trade de current
                    const new_current = state.current.filter((key, i) => i !== current_ind);

                    // On enlève le trade de "closing"
                    // On ajoute à fixed le trade avec la somme des quantités
                    let s = trade_from_current_and_receive(current_trade, received_trade);
                    s.amount2 = closed_amount+current_amount_sold;
                    const a = {
                        ...state,
                        current: new_current,
                        closing: state.closing.filter((key, i) => i !== closing_ind),
                        fixed: [s, ...state.fixed]
                    };
                    return a;
                }
                // On a fermé une partie de ce qu'il restait
                else {
                    // On enlève le montant fermé de current
                    const new_current = state.current.map((s, key) => key === current_ind ? {...s, amount2: current_amount_sold+closed_amount} : s);

                    // On ajoute le montant fermé à "closing"
                    const a = {
                        ...state,
                        current: new_current,
                        closing: state.closing.map((s, key) => key === closing_ind ? {...s, amount2: closing_amount-closed_amount} : s)
                    };
                    return a;
                }
            }

        case websocketConstants.TRADE_OPENED:
            const i = _.findIndex(state.current, x => x.code === action.payload.code);
            if (i === -1)
            {
                return {
                    ...state,
                    tradeOpened: true,
                    isCopied : action.payload.isCopied,
                    current: [action.payload, ...state.current]
                };
            }
            else {
                return {
                    ...state,
                    current: state.current.map((c, ind) => ind === i ? {...c, amount1: c.amount1 + action.payload.amount1} : c)
                };
            }


        // case websocketConstants.PAIR_PROFIT_UPDATED:
        //     return {
        //         ...state,
        //         current: state.current.map(function(currentTradeLine) {
        //                 if(action.payload.code === currentTradeLine.code )
        //                     currentTradeLine.pairProfit = action.payload.pairProfit
        //             return currentTradeLine
        //         })
        //     }


        case websocketConstants.PRICE_UPDATE:
            return {
                ...state,
                current: state.current.map(function(x) {
                    let tmp = action.payload.code === x.code ? action.payload.value : x.value2;
                    x.value2 = tmp; return x})
            };

        case tradeConstants.GET_CURRENT_TRADES:
            return state;

        case tradeConstants.DISABLE_ONE_TRADE:
            const el = state.current.find(x => x.code === action.payload)

            if (el === undefined)
                return state

            el.disabled = true;
            return {
                ...state,
                current: state.current.map(x => x.code === el.code ? el : x)
            };


        case tradeConstants.DISABLE_TAKE_PROFIT_OF_TRADE:
            const elem = state.current.find(x => x.code === action.payload)
            if (elem === undefined)
                return state

            elem.disabledTP = true;
            return {
                ...state,
                current: state.current.map(x => x.code === elem.code ? elem : x)
            };
        default:
            return state;
    }
}

export default tradeReducer;
