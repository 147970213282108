import axios from 'axios';
import round from 'round-to';
import {PAIRTRADES_URL, SERVER_IP, tradeConstants, TRADES_URL} from '../../constants';
import { websocketConstants, dev_trade_side_sell, trade_ticks_and_prices } from '../../constants';


// Thunkified version

export function getTrades()
{
    return async (dispatch, getState) =>
    {

        const allTradesFixed = await getAllTradesFixed()
        const allCurrentTrades = await getAllCurrentTrades()

        await dispatch(allTradesFixed)
        await dispatch(allCurrentTrades)


        const trades = getState()["trades"]


        const fixed = trades.fixed
        const current =trades.current

        const isEmpty = fixed.concat(current).length === 0

        if(isEmpty)
            await dispatch(notifyNoTrade(true))

    }
}
const getAllTradesFixed = async () =>
{

    const request = await axios
        .get(PAIRTRADES_URL,{withCredentials: true })

    return {
        type: tradeConstants.GET_TRADES,
        payload: request

    }
}
const getAllCurrentTrades = (data) => {
    return {
        type: tradeConstants.GET_CURRENT_TRADES,
        payload: data
    }
}
// <------------------------------------------------------------------------------->


export const disableOneTrade = (code) => {
    return {
        type: tradeConstants.DISABLE_ONE_TRADE,
        payload: code
    }
}

// code + pairProfit data in emission
/*export const updatePairProfit = (data) => {
    return {
        type: websocketConstants.PAIR_PROFIT_UPDATED,
        payload: data
    }
}*/
export const getNewTrade = (data) => {
    return {
        type: websocketConstants.TRADE_OPENED,
        payload: data
    }
}

export const closeTrade = (data) => {
    return {
        type: websocketConstants.TRADE_CLOSED,
        payload: data
    }
}



export const updateOneTrade = (data) => {

    return {
        type: websocketConstants.PRICE_UPDATE,
        payload: data
    }
}

export const tradeAddProfit = (trade) => {

    const { tick, value } = trade_ticks_and_prices[trade.code]

    trade.profit = round(value / tick * (trade.value1 - trade.value2) * trade.amount1, 2)
    trade.profit *= trade.side.toUpperCase() === dev_trade_side_sell ? 1 : -1
    trade.profit = trade.profit === 0 ? 0 : trade.profit || undefined




    return trade
}

export const notifyNoTrade = (data) => {
    return {
        type: tradeConstants.NOTIFY_NO_TRADE,
        payload: data

    }

}
