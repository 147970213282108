import React, { Component } from "react";
import AccountContainer from '../../components/Account/accountContainer';

class Account extends Component {
  render() {
    return (
      <div>
        <AccountContainer />
      </div>
    );
  }
}

export default Account;
