import axios from 'axios';
import {
    ControlPanelConstants,
    IA_STATUS_URL,
    IA_MODE_STATUS_URL,
    SWITCH_LEVERAGE_URL,
    LEVERAGE_STATUS_URL, ACCEPT_POTENTIAL_POSITION_URL,
} from '../../constants';





//TODO Bugg : #1 Request en pending #2 process.env non acessible
export const getIAStatusFromServer = async () => {

    const request = await axios.get(IA_STATUS_URL,{withCredentials: true})

    return{
        type: ControlPanelConstants.GET_IA_STATUS,
        payload: request
    }
}


export const getModeStatus = async () =>
{
    const request = await axios.get(IA_MODE_STATUS_URL,{withCredentials: true})


    return{
        type: ControlPanelConstants.GET_IA_MODE,
        payload: request
    }
}

export const getLeverageStatus = async () =>
{
    const request = await axios.get(LEVERAGE_STATUS_URL,{withCredentials: true})

    return{
        type: ControlPanelConstants.GET_LEVERAGE_STATUS,
        payload: request.data
    }
}


export const changeIAStatus = (iaStatus) =>
{
    // Only changes the local save of ia. The request is made before that.
    return {
        type: ControlPanelConstants.SWITCH_IA,
        payload: iaStatus
    }
}


export const changeIAMode = (iaStatus) =>
{

    // Only changes the local save of reverse ia. The request is made before that.
    return {
        type: ControlPanelConstants.CHANGE_IA_MODE,
        payload: iaStatus
    }
}

export const changeLeverage = async (leverageData) => {

    const {known, factor} = leverageData
    
    if(!known)
        return

    const apiResponse = await axios.post(SWITCH_LEVERAGE_URL,{} ,{withCredentials: true})


    // RTODO CHECK IF NOT THE SWITCH_LEVERAGE HAS WORKED OR NOT

    return {
        type: ControlPanelConstants.SWITCH_LEVERAGE,
        payload: apiResponse
    }


}
