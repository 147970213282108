
import React, {Component} from 'react'
import {connect} from "react-redux"
import SnackbarPresentationnal from "./SnackbarPresentationnal"
import {disableOneTrade, getTrades, notifyNoTrade} from "../Trade/tradeAction"
import {bindActionCreators} from "redux"
import {withStyles} from "@material-ui/core"



class SnackbarContainer extends Component
{
    constructor(props)
    {
        super(props)

    }

    componentDidMount()
    {

    }

    render()
    {
    return (<SnackbarPresentationnal isEmpty={this.props.isEmpty} onClose={() => this.props.notifyNoTrade(false)}/>)
    }
}


function mapStateToProps({ trades })
{
    return { isEmpty: trades.isEmpty };

}

const mapDispatchToProps = (dispatch) =>
{
    return bindActionCreators({ notifyNoTrade }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(SnackbarContainer);



