import {alertConstants, dev_trade, notificationConstants} from "../../constants"

const alertFromTrade = (type, data) =>
{
    const date = type === alertConstants.CLOSED_TRADE ? data.date2 : data.date1;
    return {
        type: type,
        market: data[dev_trade.MARKET],
        date: date,
    }
}

function alertReducer(state = {}, action)
{
    switch(action.type)
    {
        case alertConstants.OPEN_ALERT:
            return { ...alertFromTrade(action.type, action.payload) } ;

        case alertConstants.CLOSE_ALERT:
            return { ...alertFromTrade(action.type, action.payload) } ;

        default:
            return state
    }
}

export default alertReducer;
