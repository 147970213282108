import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import blue from "@material-ui/core/es/colors/blue"
import EventEmitter from 'events'

const variantIcon =
    {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

const styles1 = theme => ({
    success: {

        backgroundColor: green[600],
    },
    error: {

        backgroundColor: theme.palette.error.dark,
    },
    info:
        {
            backgroundColor: blue[900],
            opacity: 0.8,
            borderRadius: '25px',
            // backgroundColor: theme.palette.info.main,
        },
    warning: {

        backgroundColor: amber[700],
        margin: '50px'
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.95,
        marginRight: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
    },
    message:
        {
            padding: '5px',
            display: 'flex',
            alignItems: 'center',

        },
});

function MySnackbarContent(props)
{
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}

            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar"
                      className={classes.message}
                >
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}

        </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}

            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
    margin:
        {
            margin: theme.spacing.unit,
        },
});

export class CustomizedSnackbars extends React.Component
{


    constructor(props)
    {
        super(props)

        this.state =
            {
                open : false
            }

        this.handleClose = this.handleClose.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }

    handleOpen()
    {
        this.setState({ open: true });
    }

    handleClose(event, reason)
    {
        if (reason === 'clickaway')
        {
            return;
        }

        this.props.onClose()
        this.setState({ open: false });
    }
    componentWillReceiveProps(nextProps, nextContext)
    {
        if (!this.state.open && nextProps.isEmpty)
            this.setState({open: true})
    }


    render()
    {
        const { classes } = this.props

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}

                    open={this.state.open}
                    onClose={this.handleClose}
                    onClick={this.handleClose}
                >
                    <MySnackbarContentWrapper
                        variant="info"
                        className={classes.margin}
                        message="Vous n'avez aucun trade actuellement. ANA recherche une opportunité"
                    />
                </Snackbar>
            </div>
        );
    }
}

CustomizedSnackbars.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles2)(CustomizedSnackbars);
