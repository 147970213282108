import { ControlPanelConstants } from '../../constants';


function controlPanelReducer (state = {known_status: false, leverageData: {known : false , factor: 1 }}, action)
{
    switch(action.type)
    {
        case ControlPanelConstants.GET_LEVERAGE_STATUS:
            return {
                ...state,
                leverageData : { ...state.leverageData, known: true, factor: action.payload }
            }

        case ControlPanelConstants.SWITCH_LEVERAGE:
            return {
                ...state,
                leverageData : { ...state.leverageData, factor: action.payload.data.leverage }
            }


        case ControlPanelConstants.GET_IA_STATUS:
            return {
                ...state,
                known_status: true, 
                iaStatus: action.payload.data[0].iaStatut
            }
            
        case ControlPanelConstants.SWITCH_IA:
            return {
                ...state,
                iaStatus: action.payload
            }


        case ControlPanelConstants.GET_IA_MODE:
            return {
                ...state,
                known_mode_status: true,
                isAutoMode: action.payload.data[0].isAutoMode
        }





        case ControlPanelConstants.CHANGE_IA_MODE:
            return {
                ...state,
                isAutoMode: action.payload
            }


        default:
            return state

    }
}

export default controlPanelReducer;
