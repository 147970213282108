import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import HttpsRedirect from 'react-https-redirect'

import ReduxPromise from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import {createLogger} from 'redux-logger'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import './index.css';
import Template from './routes';
import accountReducer from './components/Account/accountReducer';
import controlPanelReducer from './components/ControlPanel/ControlPanelReducer';
import loginReducer from './components/Login/loginReducer';
import marketReducer from './components/Market/marketReducer';
import notificationReducer from './components/Notification/notificationReducer';
import statReducer from './components/Stats/statReducer';
import strategyReducer from './components/Strategy/strategyReducer';
import tradeReducer from './components/Trade/tradeReducer';
import indicatorReducer from './components/Indicator/indicatorReducer';
import alertReducer from "./components/Alerts/AlertReducer"
import AlertContainer from "./components/Alerts/AlertContainer";
import Grid from "@material-ui/core/Grid";


//Chargement des middlewares
let middleWares = [ReduxPromise, ReduxThunk]

if ( process.env.NODE_ENV === "development"){
    middleWares.push(createLogger())
}

const createStoreWithMiddleware = applyMiddleware(...middleWares)(createStore)

const reducers = combineReducers(
    {
        userState : loginReducer,
        indicatorState: indicatorReducer,

        ia: controlPanelReducer,
        markets: marketReducer,
        notifs: notificationReducer,
        alerts: alertReducer,
        strategies: strategyReducer,
        stats: statReducer,
        trades: tradeReducer,
        //Gestion des comptes
        accounts: accountReducer

    })

const store = createStoreWithMiddleware(reducers)


const theme = createMuiTheme(
    {

        typography:
            {
                useNextVariants: true,
                suppressDeprecationWarnings: true
            },
        palette:
            {
                primary:
                    {
                        light: '#2c3e50',
                        main: '#2c3e50',
                        dark: '#2c3e50',
                        contrastText: '#fff',
                    },
                secondary:
                    {
                        light: '#4dabf5',
                        main: '#2980b9',
                        dark: '#0276aa',
                        contrastText: '#fff'
                    },
                danger:
                    {
                        light: '#F44336',
                        main: '#F44336',
                        dark: '#F44336',
                        contrastText: '#fff'
                    }
            }
    });


ReactDOM.render(
    <Provider store={store}>
        <HttpsRedirect>
            <div>
                <AlertContainer/>
                <MuiThemeProvider theme={theme}>
                    <Template />
                </MuiThemeProvider>
            </div>
        </HttpsRedirect>
    </Provider>
    , document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
