import {
    ACCEPT_POTENTIAL_POSITION_URL,
    CANCEL_POTENTIAL_POSITION_URL,
    IndicatorActionConstants, notificationConstants,
    websocketConstants
} from "../../constants";
import axios from "axios";


export const updatePairProfitOfIndicator = (data) => {

    return {
        type: websocketConstants.PAIR_PROFIT_UPDATED,
        payload: data
    }
}
export const newPositionDetected = (data) => {
    return {
        type: websocketConstants.NEW_POSITION_DETECTED,
        payload: data
    }
}
export const positionDetectedAccepted = (data) => {
    return {
        type: websocketConstants.POSITION_DETECTED_ACCEPTED,
        payload: data
    }
}
export const positionDetectedCanceled = (data) => {
    return {
        type: websocketConstants.POSITION_DETECTED_CANCELED,
        payload: data
    }
}

/*
// On peut soit faire un action creator ou bien tout simplement un thunk qui va regrouper tout ce bordel mais qui renvoie un dispatch */
export const acceptPositionDetected = async (indicatorData) => {
    // TODO C'EST ICI QU'IL FAUT FAIRE LES AXIOS etc !
    try {
        const apiResponse = await axios.post(ACCEPT_POTENTIAL_POSITION_URL,{
            code : indicatorData.code
        } ,{withCredentials: true})


        return {
            type: IndicatorActionConstants.ACCEPT_POSITION_DETECTED,
            payload: {...indicatorData, ...apiResponse}
        }


    }
    catch(error){
        return {
            type: IndicatorActionConstants.ERROR,

            // type: IndicatorActionConstants.ERROR,
            payload: {...indicatorData, ...error}
        }
    }

}


export const cancelPositionDetected = async (indicatorData) => {
    try {
        const apiResponse = await axios.post(CANCEL_POTENTIAL_POSITION_URL,{
            code : indicatorData.code
        } ,{withCredentials: true})

        return {
            type: websocketConstants.POSITION_DETECTED_CANCELED,
            payload: {...indicatorData, ...apiResponse}


        }


    }
    catch(error){
        return {
            type: IndicatorActionConstants.ERROR,
            payload: {...indicatorData, ...error}

        }
    }
}