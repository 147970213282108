import {notificationConstants, websocketConstants} from "../../../constants";
import {
    newPositionDetected,
    positionDetectedAccepted,
    positionDetectedCanceled,
    updatePairProfitOfIndicator
} from "../indicatorAction";
import {addNotification} from "../../Notification/notificationAction";
import {iaDetectedAlert, positionDetectedAlert} from "../../Alerts/AlertAction";

export function bindSocketToIndicatorActions(dispatch, socket){
    socket.on(websocketConstants.PAIR_PROFIT_UPDATED, (data) => {
        dispatch(updatePairProfitOfIndicator(data))
    })

    socket.on(websocketConstants.NEW_POSITION_DETECTED, (data) => {

        // dispatch(addNotification(notificationConstants.NEW_POSITION_DETECTED, data))
        // dispatch(positionDetectedAlert(data))
        dispatch(newPositionDetected(data))

    })

    socket.on(websocketConstants.IA_DETECTED, (data) => {

        // dispatch(addNotification(notificationConstants.IA_DETECTED, data))
        dispatch(iaDetectedAlert(data))

    })

    socket.on(websocketConstants.POSITION_DETECTED_CANCELED, (data) => {
        dispatch(positionDetectedCanceled(data))
    })

    socket.on(websocketConstants.POSITION_DETECTED_ACCEPTED, (data) => {
        dispatch(positionDetectedAccepted(data))
    })


}