import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Eject from '@material-ui/icons/Eject';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import {
    ia_shutdown_button_labels,
    used_language,
    ia_waiting,
    IndicatorLabels,
    ia_mode_labels,
    leverage_labels
} from '../../../langues';
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import * as _ from 'lodash'
import BigNumber from "bignumber.js";

const styles = {
        container: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        buttonActive:
            {
                marginTop: 10,
                marginRight: 10,
                backgroundColor : "grey",
                textColor: "white"
            },
        buttonInactive:
            {
                marginTop: 10,
                marginRight: 10,
                backgroundColor : "white",
                textColor: "black"
            }
    };

const theme = createMuiTheme(
    {
        palette: {
            pr: {
                light: '#4dabf5',
                main: '#2980b9',
                dark: '#0276aa',
                contrastText: '#fff',
            },
            sec: red
        },
        typography: {
            useNextVariants: true
        }
    });

LeverageButton.propTypes = {
    classes: PropTypes.object.isRequired,
    onclick: PropTypes.func.isRequired
};

function LeverageButton(props) {
    const { classes, leverageFactor,known, onclick, isDemo } = props;


    const active = known && _.toNumber(leverageFactor) > 1


    if (!isDemo)
        return (
            <div style={{margin: '5px'}}>
                <MuiThemeProvider theme={theme}>
                    {known === true ?
                        <Button  variant="contained"  onClick={onclick} className={active ? classes.buttonActive : classes.buttonInactive} >
                            {active ? <Eject /> : <PlayArrow /> }
                            {active ? leverage_labels[used_language][1] : leverage_labels[used_language][0]}
                        </Button> :

                        <Button variant="contained" className={classes.button}>
                            <PlayArrow />
                            {ia_waiting[used_language]}
                        </Button>

                    }
                </MuiThemeProvider>
            </div>
        )
    else
        return <div/>
}

function mapStateToProps({ userState, ia })
{
    return { isDemo : userState.isDemo  ,ia };
}
export default withRouter(connect(mapStateToProps)(withStyles(styles)(LeverageButton)))
