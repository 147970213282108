import { statConstants } from '../../constants';
import { tradeAddProfit } from '../Trade/tradeAction';

function statReducer (state = {}, action) {

    switch(action.type) {
        case statConstants.GET_STATS_IA_TODAY:
            return { 
                ...state,
                ia: { 
                    ...state.ia,
                    today: action.payload.data.map(tradeAddProfit)
                }
            };

        case statConstants.GET_STATS_IA_WEEK:
            return { 
                ...state,
                ia: { 
                    ...state.ia,
                    week: action.payload.data.map(tradeAddProfit)
                }
            };

        case statConstants.GET_STATS_IA_MONTH:
            return { 
                ...state,
                ia: { 
                    ...state.ia,
                    month: action.payload.data.map(tradeAddProfit)
                }
            };


            case statConstants.GET_STATS_PERSON_TODAY:
            return {
                ...state, 
                person: {
                    ...state.person,
                    today: action.payload.data.map(tradeAddProfit)
                }
            };

        case statConstants.GET_STATS_PERSON_WEEK:
            return {
                ...state, 
                person: {
                    ...state.person,
                    week: action.payload.data.map(tradeAddProfit)
                }
            };
        
        case statConstants.GET_STATS_PERSON_MONTH:
            return {
                ...state, 
                person: {
                    ...state.person,
                    month: action.payload.data.map(tradeAddProfit)
                }
            };
        
        default:
            return state;
    }
}

export default statReducer;