import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import round from 'round-to';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Done from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';

import {
    disableOneTrade,
    tradeAddProfit,
    getTrades
} from './tradeAction';
import TradePresentationnal from './tradePresentationnal';
import IndicatorContainer from '../Indicator/IndicatorContainer';
import { trade_headers, used_language, trade_side_label } from '../../langues';
import { websocketConstants, trade_ticks_and_prices } from '../../constants';
import { dev_trade, dev_trade_fields, dev_trade_liquidate, dev_sort } from '../../constants';
import { MarketsInfo } from '../Market/marketAction';
import _ from 'lodash';
import {Icon, IconButton} from "@material-ui/core";

const styles = theme => ({
    button:
        {
            margin: theme.spacing.unit,
        },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    }
});

const raw_headers = trade_headers[used_language];

const headers = dev_trade_fields.map( function (colName, index)
{
    return {id: colName, label: raw_headers[index]};
});

class TradeContainer extends Component
{
    // this.props.stats.trades contains 3 fields:
    // fixed : the closed trades
    // closing : the trades we are trying to close (most certainly, there is more than 1 lot and not all of them are closed)
    // open : the trades that are not closed. You can see them change in real time, and they are updated amongst reception of PriceUpdate from the server

    en_cours = (obj) =>
    {
        return !obj[dev_trade.CLOSE_DATE];
    }

    liquidate = (socket, code) =>
    {
        if(socket === undefined)
            return null

        const toSend =
            {
                [dev_trade_liquidate.ACTION]: websocketConstants.LIQUIDATE,

                [dev_trade_liquidate.PAIR_KEY] : code,

            }

        //
        socket.emit('UserAction', toSend)
        this.props.disableOneTrade(code)


    }

    // updateTakeProfit = (socket, code, side, qty) => {
    //     if (_.isNil(socket))
    //         return null
    //
    //     const toSend =
    //         {
    //             [dev_trade_liquidate.ACTION]: websocketConstants.UPDATE_TAKEPROFIT,
    //             [dev_trade_liquidate.MARKET]: code,
    //             [dev_trade_liquidate.SIDE]: side,
    //             [dev_trade_liquidate.QUANTITY_1]: qty,
    //         }
    //
    //     socket.emit('UserAction', toSend)
    //
    //     this.props.disableTPOfTrade(code)
    // }

// Permet de designer les champs du composant table
// Accept DOM pour styliser
// dev_trade.TYPE pour choisir le bon champ
// NB : ne pas oublier { }


    fillTradeByHeadId(obj, head, undef_default) {

        const socket = this.props.userState !== undefined ? this.props.userState.socket : undefined
        if(head.id === dev_trade.MARKET) {
            const market = MarketsInfo.find(x => x.code === obj[dev_trade.MARKET])

            return (<Grid container justify='center'>
                <Avatar aria-label={market.long} style={{backgroundColor: market.color}}>
                    {market.sigle}
                </Avatar>
            </Grid>)

        }
        if (head.id === dev_trade.SIDE) {
            return trade_side_label[used_language][obj[dev_trade.SIDE].toUpperCase()]
        }
        else if (head.id === dev_trade.PROFIT) {
            const color = obj[head.id] > 0 ? 'green' : 'red'
            return obj[head.id] !== undefined ?
                <span style={{color}}>
                    {Intl.NumberFormat('de-DE', { style: 'currency', currency: trade_ticks_and_prices[obj[dev_trade.MARKET]].currency })
                        .format(obj[head.id])}
                </span>
                : undef_default
        }
        else if (head.id === dev_trade.BALANCE) {
            return obj[head.id] !== undefined  ? round(parseFloat(obj[head.id]) * 100, 2) + ' %' : undef_default
        }
        else if (head.id === dev_trade.STRENGTH) {
            return obj[head.id] !== undefined? round(parseFloat(obj[head.id]), 1) : undef_default
        }
        else if (head.id === dev_trade.STATUS) {

            return this.en_cours(obj) ? <MoreHoriz /> : <Done /> ;
        }
        else if (head.id === dev_trade.UPDATE_TAKEPROFIT && this.en_cours(obj)) {

            const disabledTP = obj.disabledTP || obj.disabled || false

            return !this.props.trades.isCopied ?
                (<Button
                    variant="contained"
                    color="primary"
                    className={this.props.classes.button}
                    // onClick={disabledTP ? () => {} : () => this.updateTakeProfit(socket, obj[dev_trade.MARKET], obj[dev_trade.SIDE], obj[dev_trade.QUANTITY_1])}
                    disabled={true}
                >
                    REMOVE TP !
                    <AddCircleOutlineSharpIcon  className={this.props.classes.rightIcon} />
                </Button>)
                : "-"

        }
        else if (head.id === dev_trade.LIQUIDATE && this.en_cours(obj)) {
            return !this.props.trades.isCopied ?
                (<Button
                    variant="contained"
                    color="secondary"
                    className={this.props.classes.button}
                    onClick={obj.disabled ? () => {} : () => this.liquidate(socket, obj[dev_trade.MARKET], obj[dev_trade.SIDE], obj[dev_trade.QUANTITY_1])} disabled={obj.disabled}
                >
                    Liquider
                    <DeleteIcon className={this.props.classes.rightIcon} />
                </Button>)
                : "-"

        }


        return obj[head.id] !== undefined ? obj[head.id] : undef_default
    }

    componentDidMount() {

        this.props.getTrades()

        // Ajouter une action dispatcher sur cela
        //     et remettre a false les deux state grace au state
        // CustomizedSnackbars.notify()


    }

    render() {

        let all_trades = this.props.trades.fixed !== undefined ?
            _.uniqBy(this.props.trades.fixed.concat(this.props.trades.current), x => x.timestamp1) : this.props.trades.current

        all_trades = !_.isNil(all_trades) ?  all_trades.map(tradeAddProfit) : all_trades


        return (
            <div>
                <IndicatorContainer classes={this.props.classes} indicators={this.props.indicators} />
                <TradePresentationnal
                    isEmpty={this.props.trades.isEmpty}
                    tradeOpened={this.props.trades.tradeOpened}
                    tradeClosed={this.props.trades.tradeClosed}
                    headers={headers}
                    rows={all_trades}
                    order={dev_sort.DESC}
                    orderBy={dev_trade.OPEN_DATE}
                    label_to_print={this.fillTradeByHeadId.bind(this)} />
            </div>
        );
    }
}



function mapStateToProps({ indicators, trades, userState }) {

    const socket = userState.socket
    return { indicators, trades,  userState, socket };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getTrades, disableOneTrade }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TradeContainer));
